@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Flink Bold';
  src: local('Flink Bold'), url(/public/fonts/flink-bold.woff) format('woff');
}

@font-face {
  font-family: 'Flink Regular';
  src: local('Flink Regular'), url(/public/fonts/flink-regular.woff) format('woff');
}

@font-face {
  font-family: 'Flink Medium';
  src: local('Flink Medium'), url(/public/fonts/flink-medium.woff) format('woff');
}

@font-face {
  font-family: 'Flink Light';
  src: local('Flink Light'), url(/public/fonts/flink-light.woff) format('woff');
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  color: white;
  font-family: 'Flink Bold', 'sans-serif';
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInDown {
  0% {
    height: 0px;
    margin-top: 3.5rem;
  }
  100% {
    height: 100%;
    margin-top: 3.5rem;
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.hero-section-animated {
  animation: fadeInLeft 1.25s;
  -webkit-animation: fadeInLeft 1.25s;
}

.signup-section-animated {
  animation: fadeInRight 1.25s ease-in-out;
  -webkit-animation: fadeInRight 1.25s;
}

.offer-section-animated:nth-child(3n + 1) {
  animation: fadeInLeft 1.25s;
  -webkit-animation: fadeInLeft 1.25s;
}

.offer-section-animated:nth-child(3n) {
  animation: fadeInRight 1.25s ease-in-out;
  -webkit-animation: fadeInRight 1.25s;
}

.offer-section-animated:nth-child(2) {
  animation: 1.25s;
  -webkit-animation: fadeInDown 1.25s;
}

.offer-section-animated:nth-child(5) {
  animation: fadeInUp 1.25s;
  -webkit-animation: fadeInUp 1.25s;
}

.ready-section-animated {
  animation: bounceInRight 1300ms;
}

.flink-logo-container {
  width: 69%;
  animation: fadeIn 3s;
}

.flag-container {
  animation: fadeInUp 2s;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.fadeIn {
  animation-name: fadeIn;
}

.video-responsive {
  --horizontal-height: 315px;
  --vertical-height: 500px;

  overflow: hidden;
  padding-bottom: var(--horizontal-height);
  position: relative;
  max-height: var(--horizontal-height);
}

.video-responsive.--vertical {
  padding-bottom: var(--vertical-height);
  max-height: var(--vertical-height);
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.footer-logo-container {
  width: 11.25rem;
  height: 2.8rem;
}

.carousel .control-dots .dot {
  background: #e82782 !important;
  box-shadow: none;
}

.carousel .control-next.control-arrow:before {
  border-left-color: #e82782 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right-color: #e82782 !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel .control-prev.control-arrow:before {
  opacity: 1;
}
.carousel.carousel-slider {
  padding-bottom: 1.25rem;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.job-decription-modal h3 {
  @apply mb-3.5 font-flink-bold;
}

.job-decription-modal p {
  @apply mb-3.5;
}

.job-decription-modal ul {
  @apply list-disc pl-8;
}

.privacy-content h3 {
  @apply mt-10 font-flink-bold text-sm md:text-lg;
}

.privacy-content p {
  @apply mb-4 mt-3.5;
}

.privacy-content ul {
  @apply list-disc pl-9;
}

.privacy-content {
  @apply mb-7 mt-3.5;
}

@layer components {
  .primary-btn {
    @apply cursor-pointer rounded-2xl bg-flinkPink py-3 px-6 text-sm uppercase text-white;
  }
}

@media (min-width: 1024px) {
  .footer-logo-container {
    width: 13.8rem;
    height: 3.4rem;
  }
}

.accordion-element {
  clip-path: polygon(0% 100%, 4% 0%, 100% 0, 100% 0, 95% 100%, 0% 100%, 0 100%);
}

.accordion-element.expanded {
  clip-path: polygon(0% 0%, 0 0, 100% 0, 95% 0, 100% 100%, 0% 100%, 4% 100%);
}

.faq-item p {
  @apply mb-4;
}

.faq-item ul {
  @apply pl-10;
  list-style: initial;
}

.faq-item b {
  @apply font-flink-bold;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
}

.manage-button {
  width: 200px;
  height: 56px;
  padding: 16px;
  border-radius: 6px;
  background-color: #feecf4;
  color: #e82782;
}

.accept-button {
  width: 200px;
  height: 56px;
  padding: 16px;
  border-radius: 6px;
  color: #feecf4;
  background-color: #e82782;
}
